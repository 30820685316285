var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "capacitar p-5", attrs: { id: "capacitar" } },
    [
      _c("h2", { staticClass: "text-pink" }, [
        _vm._v(_vm._s(_vm.$t("eresempresa.t5")))
      ]),
      _vm._l(_vm.programs, function(program, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "capacitar-item",
            on: {
              click: function($event) {
                return _vm.toggleProgram(index)
              }
            }
          },
          [
            _c("div", { staticClass: "conteiner-title px-2" }, [
              _c("h3", {
                staticClass: "title",
                domProps: { innerHTML: _vm._s(program.title) }
              }),
              _c("span", {
                class: {
                  "triangle-down": _vm.isProgramVisible(index),
                  "triangle-right": !_vm.isProgramVisible(index)
                }
              })
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isProgramVisible(index),
                    expression: "isProgramVisible(index)"
                  }
                ],
                staticClass: "items-container"
              },
              _vm._l(program.items, function(item, itemIndex) {
                return _c("div", { key: itemIndex, staticClass: "item" }, [
                  _c("div", { staticClass: "main-content" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(_vm._s(item.label))
                    ]),
                    _c("div", { staticClass: "hours" }, [
                      _vm._v(_vm._s(item.hours))
                    ])
                  ]),
                  item.details
                    ? _c("div", { staticClass: "details" }, [
                        _vm._v(_vm._s(item.details))
                      ])
                    : _vm._e()
                ])
              }),
              0
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }