// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/empresas/banner2.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.heading[data-v-d98dbab8]{\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    padding: 10% 5%;\n    padding-top: 30%;\n    color: #baff43;\n    background-size: cover;\n    text-align: left;\n    background-position: top;\n}\n.heading h1[data-v-d98dbab8]{\n    \n    font-weight: bolder;\n    font-size: 1.5rem;\n}\n@media screen and (min-width: 320px){\n.heading[data-v-d98dbab8]{\n    padding-top: 0%;\n}\n.heading h1[data-v-d98dbab8]{\n        \n        font-size: 2rem;\n}\n}\n@media screen and (min-width: 425px) {\n.heading[data-v-d98dbab8]{\n    padding-top: 35%;\n}\n.heading h1[data-v-d98dbab8]{\n        \n        font-size: 2rem;\n}\n}\n@media screen and (min-width: 768px) {\n.heading[data-v-d98dbab8]{\n    padding-top: 20%;\n}\n.heading h1[data-v-d98dbab8]{\n        \n        font-size: 2.5rem;\n}\n}\n@media screen and (min-width: 1000px){\n.heading[data-v-d98dbab8]{\n    padding-top: 15%;\n}\n.heading h1[data-v-d98dbab8]{\n        \n        font-size: 3rem;\n}\n}\n@media screen and (min-width: 1300px){\n.heading[data-v-d98dbab8]{\n    padding-top: 15%;\n}\n.heading h1[data-v-d98dbab8]{\n        \n        font-size: 3em;\n}\n}\n.contrata[data-v-d98dbab8]{\n    padding: 100px 5%;\n}\n.contrata p[data-v-d98dbab8]{\n        padding: 0px 5%;\n}\n.contrata h3[data-v-d98dbab8]{\n    /* color: #ff00ff; */\n    margin-bottom: 20px;\n    font-size: 1.5rem;\n    font-weight: bold;\n}\n@media screen and (min-width: 1024px){\n.contrata h3[data-v-d98dbab8]{\n        font-size: 1.75rem;\n}\n.contrata p[data-v-d98dbab8]{\n        padding: 0px 15%;\n}\n}\n#empresas p[data-v-d98dbab8]{\n    font-size: 1.25rem;\n}\n.triangle[data-v-d98dbab8]{\n    position: absolute;\n    top:0;\n    left: 3em;\n}\n\n\n", ""]);
// Exports
module.exports = exports;
