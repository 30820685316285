var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "carousel" } },
    [
      _c(
        "b-carousel",
        {
          staticStyle: { "text-shadow": "1px 1px 2px #333" },
          attrs: {
            id: "carousel-1",
            interval: 50000000,
            fade: "",
            controls: "",
            background: "#ababab"
          },
          model: {
            value: _vm.slide,
            callback: function($$v) {
              _vm.slide = $$v
            },
            expression: "slide"
          }
        },
        [
          _c("b-carousel-slide", {
            attrs: {
              "caption-html": _vm.$t("carouselEmpresa.c1"),
              "img-src": require("../../assets/banner/foto1.webp")
            }
          }),
          _c("b-carousel-slide", {
            staticClass: "azul",
            attrs: {
              "caption-html": _vm.$t("carouselEmpresa.c2"),
              "img-src": require("../../assets/banner/foto2.webp")
            }
          }),
          _c("b-carousel-slide", {
            attrs: {
              "caption-html": _vm.$t("carouselEmpresa.c3"),
              "img-src": require("../../assets/banner/foto3.webp")
            }
          }),
          _c("b-carousel-slide", {
            attrs: {
              "caption-html":
                "<span style='color: white;'>" +
                _vm.$t("carouselEmpresa.c4") +
                "</span>",
              "img-src": require("../../assets/banner/foto4.webp")
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }