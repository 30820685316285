var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mb-5" },
        [
          _c(
            "b-button",
            {
              staticClass: "px-5 py-2 rounded-pill",
              attrs: {
                variant: "black",
                href: "https://cloud.edu.mindhubweb.com/empresas"
              }
            },
            [_vm._v(_vm._s(_vm.$t("eresempresa.button4")))]
          )
        ],
        1
      ),
      _vm._l(_vm.immersions, function(value, key) {
        return _c(
          "div",
          { key: key, staticClass: "px-6" },
          [
            _c("div", { staticClass: "bgk-black py-5" }, [
              _c("p", { staticClass: "h1 text-white" }, [_vm._v(_vm._s(key))])
            ]),
            _c(
              "b-container",
              [
                _c(
                  "b-row",
                  {
                    staticClass: "py-4 px-6 immersions",
                    attrs: { "align-h": "center" }
                  },
                  _vm._l(value, function(immersion, index) {
                    return _c(
                      "b-col",
                      {
                        key: index,
                        staticClass: "immersion p-3",
                        attrs: { cols: "12", lg: "6" }
                      },
                      [
                        _c("div", { staticClass: "line" }),
                        _c("div", { staticClass: "immersion-card bg-light" }, [
                          _c("h3", {
                            staticClass: "font-weight-bolder",
                            domProps: {
                              innerHTML: _vm._s(_vm.fancyText2(immersion.title))
                            }
                          }),
                          _c("p", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.$tc("eresempresa.multi.duration")) +
                                  ":"
                              )
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(immersion.totalHours) +
                                " " +
                                _vm._s(_vm.$tc("eresempresa.multi.hours"))
                            )
                          ]),
                          _c("p", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.$tc("eresempresa.multi.modality")) +
                                  ":"
                              )
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .$tc("modalities." + immersion.modality)
                                    .toUpperCase()
                                ) +
                                " / " +
                                _vm._s(_vm.fancyText(immersion.courseType))
                            )
                          ]),
                          _c("p", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.$tc("eresempresa.multi.date")) + ":"
                              )
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.fancyDate(immersion.initDate))
                            )
                          ])
                        ])
                      ]
                    )
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }