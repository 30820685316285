var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "empresas" } },
    [
      _c("CarouselEmpresa"),
      _c("div", { staticClass: "contrata" }, [
        _c("h3", { domProps: { innerHTML: _vm._s(_vm.$t("eresempresa.t2")) } }),
        _c("p", { domProps: { innerHTML: _vm._s(_vm.$t("eresempresa.p1")) } })
      ]),
      _c("Ofrecerte"),
      _c(
        "div",
        { staticClass: "my-5" },
        [
          _c("p", {
            staticStyle: { "margin-bottom": "3rem" },
            domProps: { innerHTML: _vm._s(_vm.$t("eresempresa.p4")) }
          }),
          _c(
            "b-button",
            {
              staticClass: "px-5 py-2 rounded-pill",
              attrs: {
                href: "https://cloud.edu.mindhubweb.com/empresas",
                variant: "black"
              }
            },
            [_vm._v(_vm._s(_vm.$t("somosmh.button")))]
          )
        ],
        1
      ),
      _c("div", { staticClass: "mt-5" }, [_c("CarouselEmpresas")], 1),
      _c("Capacitar"),
      _c(
        "div",
        { staticClass: "my-5" },
        [
          _c(
            "b-button",
            {
              staticClass: "px-5 py-2 rounded-pill",
              attrs: {
                href:
                  "https://image.edu.mindhubweb.com/lib/fe3211737164047f761d73/m/1/5c419259-03cf-4938-a58a-f6e8944b246f.pdf",
                variant: "black"
              }
            },
            [_vm._v(_vm._s(_vm.$t("eresempresa.button3")))]
          )
        ],
        1
      ),
      _c("FaqEmpresa"),
      _c("Multiempresas"),
      _c(
        "b-modal",
        { attrs: { size: "xl", id: "download-b2b", "hide-footer": "" } },
        [
          _c("iframe", {
            staticClass: "w-100",
            staticStyle: { height: "500px" },
            attrs: {
              src: "https://info.mindhubweb.com/syllabus-b2b",
              frameborder: "0"
            }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }