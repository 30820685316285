var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ofrecerte" },
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "p-0" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mb-5 px-5 py-2 rounded-pill",
                      attrs: {
                        href: "https://cloud.edu.mindhubweb.com/empresas",
                        variant: "black"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("eresempresa.button1")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "p-0", attrs: { cols: "12", lg: "4" } },
                [
                  _c("div", { staticClass: "ofrecerte-card card1" }, [
                    _c("div", { staticClass: "ofrecerte-desc" }, [
                      _c("h3", {
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("eresempresa.card1.title"))
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "content-wrapper" },
                        [
                          _c("p", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("eresempresa.card1.desc")
                              )
                            }
                          }),
                          _c("b-button", {
                            staticClass: " rounded-pill",
                            attrs: {
                              variant: "black",
                              href:
                                "https://image.edu.mindhubweb.com/lib/fe3211737164047f761d73/m/1/5c419259-03cf-4938-a58a-f6e8944b246f.pdf",
                              target: "_blank"
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.$t("eresempresa.card1.a"))
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "b-col",
                { staticClass: "p-0", attrs: { cols: "12", lg: "4" } },
                [
                  _c("div", { staticClass: "ofrecerte-card card2" }, [
                    _c("div", { staticClass: "ofrecerte-desc" }, [
                      _c("h3", {
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("eresempresa.card2.title"))
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "content-wrapper" },
                        [
                          _c("p", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("eresempresa.card2.desc")
                              )
                            }
                          }),
                          _c("b-button", {
                            staticClass: " rounded-pill",
                            attrs: {
                              variant: "black",
                              href:
                                "https://image.edu.mindhubweb.com/lib/fe3211737164047f761d73/m/1/5c419259-03cf-4938-a58a-f6e8944b246f.pdf",
                              target: "_blank"
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.$t("eresempresa.card2.a"))
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "b-col",
                { staticClass: "p-0", attrs: { cols: "12", lg: "4" } },
                [
                  _c("div", { staticClass: "ofrecerte-card card3" }, [
                    _c("div", { staticClass: "ofrecerte-desc" }, [
                      _c("h3", {
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("eresempresa.card3.title"))
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "content-wrapper" },
                        [
                          _c("p", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("eresempresa.card3.desc")
                              )
                            }
                          }),
                          _c("b-button", {
                            staticClass: " rounded-pill",
                            attrs: {
                              variant: "black",
                              href:
                                "https://image.edu.mindhubweb.com/lib/fe3211737164047f761d73/m/1/5c419259-03cf-4938-a58a-f6e8944b246f.pdf",
                              target: "_blank"
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.$t("eresempresa.card3.a"))
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }