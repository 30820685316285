var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-5" },
    [
      _vm.mq.matches
        ? [
            _c(
              "b-carousel",
              { staticClass: "empresas-carousel", attrs: { interval: 2000 } },
              [
                _c(
                  "b-carousel-slide",
                  [
                    _c("template", { slot: "img" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/01-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/03-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/12-1.png"),
                                alt: "empresas"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                ),
                _c(
                  "b-carousel-slide",
                  [
                    _c("template", { slot: "img" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/04-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/05-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/06-1.png"),
                                alt: "empresas"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                ),
                _c(
                  "b-carousel-slide",
                  [
                    _c("template", { slot: "img" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/08-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/09-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/10-1.png"),
                                alt: "empresas"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                ),
                _c(
                  "b-carousel-slide",
                  [
                    _c("template", { slot: "img" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/07-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/14-1.png"),
                                alt: "empresas"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                )
              ],
              1
            )
          ]
        : [
            _c(
              "b-carousel",
              { staticClass: "empresas-carousel", attrs: { interval: 2000 } },
              [
                _c(
                  "b-carousel-slide",
                  [
                    _c("template", { slot: "img" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/01-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/03-1.png"),
                                alt: "empresas"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                ),
                _c(
                  "b-carousel-slide",
                  [
                    _c("template", { slot: "img" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/04-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/05-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/06-1.png"),
                                alt: "empresas"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                ),
                _c(
                  "b-carousel-slide",
                  [
                    _c("template", { slot: "img" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/07-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/08-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/09-1.png"),
                                alt: "empresas"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                ),
                _c(
                  "b-carousel-slide",
                  [
                    _c("template", { slot: "img" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/10-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/12-1.png"),
                                alt: "empresas"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                ),
                _c(
                  "b-carousel-slide",
                  [
                    _c("template", { slot: "img" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/logos/14-1.png"),
                                alt: "empresas"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }