var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "faq p-5", attrs: { id: "faq" } },
    [
      _c("h2", { staticClass: "faq-title" }, [
        _vm._v(_vm._s(_vm.$t("eresempresa.t6")))
      ]),
      _vm._l(_vm.questions, function(question, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "faq-item",
            on: {
              click: function($event) {
                return _vm.toggleAnswer(index)
              }
            }
          },
          [
            _c("div", { staticClass: "faq-question" }, [
              _c("h4", [
                _vm._v(
                  _vm._s(_vm.$t("eresempresa.faqs.question[" + index + "]"))
                )
              ]),
              _c("span", {
                class: {
                  "triangle-down": _vm.isAnswerVisible(index),
                  "triangle-right": !_vm.isAnswerVisible(index)
                }
              })
            ]),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isAnswerVisible(index),
                    expression: "isAnswerVisible(index)"
                  }
                ],
                staticClass: "faq-answer"
              },
              [_vm._v(_vm._s(_vm.$t("eresempresa.faqs.answer[" + index + "]")))]
            ),
            index < _vm.questions.length - 1
              ? _c("hr", { staticClass: "guion" })
              : _vm._e()
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }